import React from 'react';
import { Helmet } from "react-helmet";

const Team = () => {
    return (
        <div className="team cnt-wrap mx-width">
            <Helmet>
                <title>Team Kuro Cadence</title>
                <meta name="description" content="Team Kuro Cadence info: Leadership, CEO, CTO, Management, Partners' details" />
                <meta name="keywords" content="Startup, Tech company, innovators" />
                <meta property="og:title" content="Team Kuro Cadence" />
                <meta property="og:description" content="Team Kuro Cadence info: Leadership, CEO, CTO, Management, Partners' details" />
                <meta property="og:url" content="https://kurocadence.com/team" />
                <link rel="canonical" href="https://kurocadence.com/team" />
                <link rel="alternate" hreflang="x-default" href="https://www.kurocadence.com/team" />
            </Helmet>
            <div className="text">
                <h2>Our core team</h2>
                <p>We are a diverse group of indivduals who have come together to create and build something innovative and yet simple.</p>
                <p>Our Head of operations, whose dedication and work ethic makes even veteran marathon runners look like amateurs.</p>
                <p>Our CTO, whose Coding prowess and quick learning skills are second to none. The same can be said about his skilful use of sarcastic retorts!</p>
                <p>And Finally, A Maverick CEO and Founder, whose eccentricities knows no bounds.</p>
            </div>
        </div>
    );
}

export default Team;