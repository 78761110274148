import React from 'react';
import { Helmet } from "react-helmet";

const Careers = () => {
    return (
        <div className="careers cnt-wrap mx-width">
            <Helmet>
                <title>Careers - Kuro Cadence | Work at India's New-age Lifestyle Tech Startup</title>
                <meta name="description" content="Apply for working at Kuro Cadence, India's new-age Lifestyle Tech Startup" />
                <meta name="keywords" content="Gaming, Tech Startup, Work, Careers, Advanced Technologies, Software, design, development, Adobe, Photoshop, Illustrator, Javascript, Cloud Computing, Full-stack, Content Creators, Wanted, Looking for, Near Hyderabad, Youtube, Instagram, Twitter, Facebook, e-commerce, employment, Equal Opportunities, Women Empowerment" />
                <meta property="og:title" content="Kuro Cadence: Careers" />
                <meta property="og:description" content="Apply for working at Kuro Cadence, India's new-age Lifestyle Tech Startup" />
                <meta property="og:url" content="https://kurocadence.com/careers" />
                <link rel="canonical" href="https://kurocadence.com/careers" />
                <link rel="alternate" hreflang="x-default" href="https://www.kurocadence.com/careers" />
            </Helmet>
            <div className="text">
                <h2>We're building something amazing</h2>
                <h4>Want to join the team?</h4>
                <p>We're always looking for individuals who are driven towards technology and gaming.<br/>
                <p>Kuro cadence believes in providing Equal Opportunities, Safe and Creative Enviroment to Work in.
                <br/>We at Kuro Cadence strongly believe in Empowering Goal-driven Individuals</p>
                <h4>Hit us up with your resume and what makes Kuro Cadence the right place for you to work at</h4>
                <svg className="mail" width="15" height="15" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/></svg><a href="mailto:careers@kurocadence.com"><strong>careers@kurocadence.com</strong></a><br/></p>
            </div>
        </div>
    );
}

export default Careers;