import React from 'react';
import { Helmet } from "react-helmet";

const Contactus = () => {
    return (
        <div className="contactus cnt-wrap mx-width">
            <Helmet>
                <title>Contact Us - Kuro Cadence</title>
                <meta name="description" content="Contact, Call, Email Kuro Cadence for any Info, Partnerships, Affiliate tie-up, Franchise Opportunities" />
                <meta name="keywords" content="Startup, Tech company,  Kuro Cadence Office, Address, management, innovative, Partnerships, e-commerce, Trade, Wholesale, retail, Affiliate tie-up, Franchise Opportunities" />
                <meta property="og:title" content="Kuro Cadence: Contact Us" />
                <meta property="og:description" content="Contact, Call, Email Kuro Cadence for any Info, Partnerships, Affiliate tie-up, Franchise Opportunities" />
                <meta property="og:url" content="https://kurocadence.com/contactus" />
                <link rel="canonical" href="https://kurocadence.com/contactus" />
                <link rel="alternate" hreflang="x-default" href="https://www.kurocadence.com/contactus" />
            </Helmet>
            <div className="text">
                <h2 className="pri-4">Partner with Us</h2>
                <p>For questions or inquiries regarding Partnerships or any general queries, contact<br/>
                <svg className="mail" width="15" height="15" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/></svg><a href="mailto:corporate@kurocadence.com"><strong>corporate@kurocadence.com</strong></a></p>
                <h3 className="pri-5">Mr. Abbhinav,</h3>
                <p> Head of Operations,<br/>
                <svg className="phone" width="15" height="15" viewBox="0 0 24 24"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg><a href="tel:+919652991916"><strong>+91 965-299-1916</strong></a><br/>
                Mon-Fri 8:30 AM - 6:00 PM (IST)<br/>
                Sat 9:30 AM - 3:00 PM (IST)</p>
            </div>
            <div className="text">
                <h2 className="pri-4">Press &amp; Media</h2>
                <p>For questions or inquiries related to press and media, contact<br/>
                <svg className="mail" width="15" height="15" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/></svg><a href="mailto:media@kurocadence.com"><strong>media@kurocadence.com</strong></a></p>
            </div>
            <div className="text">
                <h2 className="pri-4">Legal</h2>
                <p>For legal inquiries, please first review our terms of service and privacy notice. For all other legal questions or concerns, contact<br/>
                <svg className="mail" width="15" height="15" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/></svg><a href="mailto:legal@kurocadence.com"><strong>legal@kurocadence.com</strong></a></p>
            </div>
            <div className="text">
                <h2 className="pri-4">Sales</h2>
                <p><svg className="phone" width="15" height="15" viewBox="0 0 24 24"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg><a href="tel:+919652991916"><strong>+91 965-299-1916</strong></a><br/>
                <svg className="mail" width="15" height="15" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/></svg><a href="mailto:sales@kurocadence.com"><strong>sales@kurocadence.com</strong></a></p>
            </div>
            <div className="text">
                <h2 className="pri-4">For any Other Queries</h2>
                <p>Please Email Us at <svg className="mail" width="15" height="15" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/></svg><a href="mailto:info@kurocadence.com"><strong>info@kurocadence.com</strong></a></p>
            </div>
        </div>
    );
}

export default Contactus;