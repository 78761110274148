import React from 'react';
import { Switch, Route } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './components/common/ScrollToTop';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contactus from './pages/Contactus';
import Careers from './pages/Careers';
import Team from './pages/Team';
import './App.css';

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <Header/>
            <div className="main">
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/about" component={About} />
                <Route exact path="/contactus" component={Contactus} />
                <Route exact path="/careers" component={Careers} />
                <Route exact path="/team" component={Team} />
            </Switch>
                <div className="wa-chat">
                    <a href="https://api.whatsapp.com/send?phone=919652991916&text=Hello! I have a query on Kuro Cadence" target="_blank" rel="noopener noreferrer">
                        <svg fill="#fff" viewBox="0 0 96 96"><title>Chat with Kuro Cadence on whatsapp</title><path d="M67.4,28.5c-5.1-5.2-12-8-19.3-8c-15,0-27.2,12.2-27.2,27.3c0,4.8,1.3,9.5,3.6,13.6l-3.9,14.1l14.4-3.8 c4,2.2,8.5,3.3,13,3.3h0c15,0,27.3-12.2,27.3-27.3C75.4,40.5,72.5,33.7,67.4,28.5L67.4,28.5z M48.1,70.4L48.1,70.4 c-4.1,0-8.1-1.1-11.5-3.2l-0.8-0.5L27.2,69l2.3-8.4l-0.5-0.9c-2.3-3.6-3.5-7.8-3.5-12.1c0-12.5,10.2-22.7,22.7-22.7 c6,0,11.7,2.4,16,6.6c4.3,4.3,6.6,10,6.6,16C70.8,60.3,60.6,70.4,48.1,70.4L48.1,70.4z M60.5,53.5c-0.7-0.3-4-2-4.7-2.2 c-0.6-0.2-1.1-0.3-1.5,0.3c-0.5,0.7-1.8,2.2-2.2,2.7c-0.4,0.5-0.8,0.5-1.5,0.2c-0.7-0.3-2.9-1.1-5.5-3.4c-2-1.8-3.4-4-3.8-4.7 c-0.4-0.7,0-1.1,0.3-1.4c0.3-0.3,0.7-0.8,1-1.2c0.3-0.4,0.5-0.7,0.7-1.1c0.2-0.5,0.1-0.8-0.1-1.2c-0.2-0.3-1.5-3.7-2.1-5.1 c-0.5-1.3-1.1-1.1-1.5-1.2c-0.4,0-0.8,0-1.3,0c-0.5,0-1.2,0.2-1.8,0.8c-0.6,0.7-2.4,2.3-2.4,5.7s2.4,6.6,2.8,7 c0.3,0.5,4.8,7.3,11.6,10.3c1.6,0.7,2.9,1.1,3.9,1.4c1.6,0.5,3.1,0.4,4.3,0.3c1.3-0.2,4-1.6,4.6-3.2c0.6-1.6,0.6-3,0.4-3.2 C61.7,54,61.2,53.8,60.5,53.5L60.5,53.5z"/></svg>
                    </a>
                </div>
            </div>
            <Footer/>
        </Router>
    );
}

export default App;