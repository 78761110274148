import React from 'react';
import { Helmet } from "react-helmet";

const About = () => {
    return (
        <div className="about cnt-wrap mx-width">
            <Helmet>
                <title>About Us - Kuro Cadence</title>
                <meta name="description" content="About Us - Kuro Cadence | Personalized Cutting-edge technological Computing, Gaming, Lifestyle Solutions at affordable prices and an extensive range of technological support" />
                <meta name="keywords" content="about, kuro cadence, startup, hyderabad, India, high-performance, manufacture, distribute, customize, high-end, gaming, machines, workstations, tenders, bulk, contracts, sales, organisations, one-stop, solution, best, cheap price, great service, excellent reviews," />
                <meta property="og:title" content="Kuro Cadence: About Us" />
                <meta property="og:description" content="Office, Home, Gaming Computer Accesories: Keyboards, Mouse, Monitors, Webcams, Streaming Mic, Headphones, Capture Card, LAN Cables, UPS, Surge Protectors" />
                <meta property="og:url" content="https://kurocadence.com/about" />
                <link rel="canonical" href="https://kurocadence.com/about" />
	            <link rel="alternate" hreflang="x-default" href="https://www.kurocadence.com/about" />
            </Helmet>
            <div className="text">
                <h2>About Kuro Cadence</h2>
                <p>KURO CADENCE is a pioneer in providing high-performance System Integrations, PC peripherals and components to Government Organisations, Private IT Companies, Universities, Educational Institutions, Research Organisations, Content Creators, Animators, gamers, enthusiasts and e-sports athletes.</p>
                <p>With vision, commitment, and steadfast determination, we manufacture and distribute various customized high-end gaming machines, and high performance workstations to meet the unique needs for gamers, businesses, government agencies, educational institutions and other end-users.</p>
                <p>Kuro Cadence was founded with two simple goals in mind. It is to provide our customers with both personalized cutting edge technology at affordable prices and an extensive range of technological support.</p>
                <p>Assisting our customers through the technological transition, we are committed to provide the best prices for all computing needs. When it comes to value, Kuro Cadence leaves the competitors behind.</p>
                <p>Our computer systems are assembled carefully, rigorously tested and built to last for the long run. If you are looking for a wide selection of products, customization, and excellent technological services at the best available prices, Kuro Cadence is your ultimate choice!</p>
            </div>
        </div>
    );
}

export default About;